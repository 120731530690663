import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import './assets/css/theme.css';
import './assets/css/style.css';
// import './assets/css/inonic.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import Login from './pages/Login';
import LeftNav from './layout/LeftNav';
import TopNav from './layout/TopNav';
import Error404 from './global/Error404';
import Logout from './pages/Logout';
import Dashboard from './pages/Dashboard';
// import SelfProfilePage from './pages/SelfProfilePage';

import UserPage from './pages/UserPage';

import CatoryPage from './pages/CatoryPage';

import GetStarted from './pages/GetStarted';
import CoverListsPage from './pages/CoverListsPage';
import CoverListDetails from './components/coverlists/CoverListDetails';

import PolicyPaidBy from './pages/PolicyPaidBy';
import PolicyDetails from './components/policypaidbyid/PolicyDetails';
import ReferralTransactionsPage from './pages/ReferralTransactionsPage';
import AgentSalesUnitPage from './pages/AgentSalesUnitPage';
import AgentDetails from './components/agent/AgentDetails';

class App extends Component {
  render() {
    // console.log(this.props);
    let login_layout = true;
    if (this.props.data.login_layout === 'STEPHEN_WHOCODED') {
      login_layout = false;
    } else {
      login_layout = true;
    }
    // console.log(login_layout);
    return (
      <>
        <div className={login_layout ? 'st-bg-gray3 st-scroll' : ''}>
          {login_layout ? <LeftNav /> : ''}

          <div
            className={
              login_layout
                ? `content ht-100v ${this.props.data.mmm ? 'stttttg' : ''} pd-0`
                : ''
            }
          >
            {login_layout ? <TopNav /> : ''}
            <div className={login_layout ? 'container pd-x-0 p-0' : ''}>
              {login_layout ? <div className='st-empty'></div> : ''}
              <div className={login_layout ? 'content-body' : ''}>
                <Switch>
                  <Route path='/' exact component={Login} />
                  <Route path='/logout' exact component={Logout} />
                  <Route path='/login' exact component={Login} />
                  <Route path='/getstarted' exact component={GetStarted} />
                  <Route path='/dashboard' exact component={Dashboard} />
                  {/* <Route path='/profile' exact component={SelfProfilePage} /> */}

                  <Route path='/users' exact component={UserPage} />

                  <Route path='/vehicles' exact component={CatoryPage} />
                  <Route path='/policies' exact component={PolicyPaidBy} />
                  <Route path='/policies/:id' exact component={PolicyDetails} />
                  <Route
                    path='/referral-transactions'
                    exact
                    component={ReferralTransactionsPage}
                  />
                  <Route path='/agent' exact component={AgentSalesUnitPage} />
                  <Route path='/agent/:id' exact component={AgentDetails} />

                  <Route path='/coverlists' exact component={CoverListsPage} />
                  <Route
                    path='/coverlists/:id'
                    exact
                    component={CoverListDetails}
                  />

                  <Route
                    render={function () {
                      return (
                        <div className='st-e-h'>
                          <Error404 />
                        </div>
                      );
                    }}
                  />
                </Switch>
              </div>
              {login_layout ? <div className='st-empty-larg'></div> : ''}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    data: store,
  };
};

export default connect(mapStoreToProps)(App);
