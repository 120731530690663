import React, { Component } from 'react';
import { connect } from 'react-redux';
import { login_layout } from '../store/actions/LoginLayout';
import { Key, Mail } from 'react-feather';
import Particles from 'react-particles-js';
import Spinner from '../global/Spinner';
import { toast } from 'react-toastify';
import Axios from 'axios';
import { Home } from '../global/Home';
import Toaster from '../global/Toaster';
import img from '../assets/svg/haba_color.svg';
import img2 from '../assets/img/LOGOW.png';
import { BounceUp, FadeIn } from 'animate-components';
import { Link } from 'react-router-dom';
import ForgottenPass from '../global/ForgottenPass';
import { passwordStrength } from 'check-password-strength';

const defaultOptions = [
  {
    id: 0,
    value: 'Too weak',
    minDiversity: 0,
    minLength: 0,
  },
  {
    id: 1,
    value: 'Weak',
    minDiversity: 0,
    minLength: 1,
  },
  {
    id: 2,
    value: 'Medium',
    minDiversity: 1,
    minLength: 3,
  },
  {
    id: 3,
    value: 'Strong',
    minDiversity: 3,
    minLength: 4,
  },
];
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      switch: '',
      email: '',
      password: '',
      loading: false,
      strenght: '',
      type: true,
    };
  }

  ErrorHandler = (message) => {
    //console.clear();
    console.log(message);
    this.setState({ loading: false });
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
    });
  };

  handleChange = (event) => {
    if (event.target.type !== 'files') {
      this.setState({ [event.target.name]: event.target.value });
      if (event.target.name === 'password') {
        this.setState({
          strenght: passwordStrength(this.state.password, defaultOptions).value,
        });
        if (
          passwordStrength(this.state.password, defaultOptions).value ==
          'Too weak'
        ) {
          this.setState({ css: 'text-danger' });
        } else if (
          passwordStrength(this.state.password, defaultOptions).value == 'Weak'
        ) {
          this.setState({ css: 'text-danger' });
        } else if (
          passwordStrength(this.state.password, defaultOptions).value ==
          'Medium'
        ) {
          this.setState({ css: 'text-warning' });
        } else if (
          passwordStrength(this.state.password, defaultOptions).value ==
          'Strong'
        ) {
          this.setState({ css: 'text-success' });
        }
      }
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    Axios.post(`${Home}customer-care/auth/login`, {
      email: this.state.email,
      password: this.state.password,
    })
      .then((res) => {
        console.log(res);
        this.setState({ loading: false });
        let { data } = res;
        if (res.data.success) {
          localStorage.setItem('userToken', JSON.stringify(data.payload.token));
          this.props.history.push('/dashboard');
        } else {
          this.ErrorHandler(data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          const { data } = err.response;
          this.ErrorHandler(data.message);
        } else {
          this.ErrorHandler(err.message);
        }
      });
  };

  componentDidMount() {
    this.props.dispatch(login_layout('STEPHEN_WHOCODED'));
  }

  componentWillUnmount() {
    this.props.dispatch(login_layout(''));
  }
  Switch = (name) => {
    this.setState({ switch: name });
  };
  render() {
    return (
      <>
        {this.state.switch === 'WHOCODED' ? (
          <ForgottenPass close={() => this.Switch('')} />
        ) : (
          ''
        )}
        <div className=''>
          <Toaster />

          <div className=''>
            <div className='content-l d-flex vh-100 justify-content-center align-items-center bgg'>
              <form onSubmit={this.handleSubmit} className='login-form'>
                <div className='card mb-0'>
                  <div className='card-body'>
                    <div className='text-center mb-3'>
                      <div className='d-inline-flex align-items-center justify-content-center mb-4 mt-2'>
                        <img className='img-fluid h-48px' src={img} />
                      </div>
                      <h5 className='text-primary2 mg-b-5 text-center'>
                        Login to your account
                      </h5>
                      <p className='text-primary22 mg-b-40 text-muted'>
                        Enter your credentials below
                      </p>
                    </div>

                    <div className='form-group'>
                      <label className='text-primary22 st-label'>
                        Email address
                      </label>
                      <input
                        onChange={this.handleChange}
                        name='email'
                        value={this.state.email}
                        type='email'
                        required
                        className='form-control st-login-f'
                        id='inlineFormInputGroup'
                        placeholder=''
                      />
                    </div>
                    <div className='form-group'>
                      <label className='text-primary22 st-label'>
                        Password
                      </label>
                      <input
                        name='password'
                        value={this.state.password}
                        onChange={this.handleChange}
                        type='password'
                        required
                        className='form-control st-login-f'
                        id='inlineFormInputGroup'
                        placeholder=''
                      />
                      <small
                        style={{ fontSize: '14px' }}
                        className={`${this.state.css}`}
                      >
                        {this.state.strenght}
                      </small>
                    </div>
                    <div
                      className='text-primary22 tx-13 mg-t0 mb-3 tx-center text-right'
                      style={{ fontWeight: '900' }}
                    >
                      <Link
                        onClick={() => this.Switch('WHOCODED')}
                        className='text-primary3'
                        to='#'
                      >
                        Forgotten password?
                      </Link>
                    </div>
                    <div className='form-group'>
                      {this.state.loading ? (
                        <Spinner size={25} />
                      ) : this.state.password.length > 0 &&
                        this.state.email.length > 0 ? (
                        <button
                          type='submit'
                          className='btn btn-primary st-btn shadow btn-block btn-sm'
                        >
                          Login
                        </button>
                      ) : (
                        <button
                          type='button'
                          disabled={false}
                          className='btn btn-primary st-btn btn-sm shadow btn-block'
                        >
                          Login
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    data: store,
  };
};

export default connect(mapStoreToProps)(Login);
