import React, { Component } from 'react';
import { BounceRight, FadeIn } from 'animate-components';
import { connect } from 'react-redux';

import Axios from 'axios';
import { Home } from '../../../../global/Home';
import Spinner from '../../../../global/Spinner';

import img from '../../../../assets/img/profile.png';

import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

let token = '';

const ListUsers = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [searchQuery, setSearchQuery] = useState('');
  const [statusQuery, setStatusQuery] = useState('');
  const [roleQuery, setRoleQuery] = useState('');

  const handleViewPoliciesPaidBy = (userId) => {
    // Navigate to another page with the user ID appended as a query parameter
    history.push(`/policies?policy_paid_by_id=${userId}`);
  };

  const handleViewPoliciesHolder = (userId) => {
    // Navigate to another page with the user ID appended as a query parameter
    history.push(`/policies?policy_holder_id=${userId}`);
  };

  const LoadData = (page = currentPage) => {
    setLoading(true);
    Axios.get(`${Home}customer-care/users?page=${page}`, {
      params: {
        search: searchQuery,
        status: statusQuery,
        role: roleQuery,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        console.log(res);
        setData(res.data.data);
        setTotalPages(res.data.meta.last_page);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    // Load token when component mounts
    if (localStorage.getItem('userToken')) {
      token = JSON.parse(localStorage.getItem('userToken'));
      LoadData();
    }
  }, []);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      LoadData(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      LoadData(currentPage + 1);
    }
  };

  const handleSearch = () => {
    console.log('search triggered');
    //reset pages before performing search
    setCurrentPage(1);
    // If search query is empty, reset to default

    if (!searchQuery && !statusQuery && !roleQuery) {
      setCurrentPage(1); // Reset to first page
      LoadData();
    } else {
      // Convert each search query to string and trim whitespace
      const search = searchQuery.toString().trim();
      const status = statusQuery.toString().trim();
      const role = roleQuery.toString().trim();

      // Create an object to hold search parameters
      const searchParams = {
        page: 1, // Start with the first page
      };
      console.log('Search Params:', searchParams);
      // Add each search query to the corresponding parameter only if it's not empty
      if (search) searchParams.search = search;
      if (status) searchParams.status = status;
      if (role) searchParams.role = role;

      // Perform search based on query parameters
      setLoading(true);
      Axios.get(`${Home}customer-care/users`, {
        params: searchParams,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          console.log('Search Response:', res.data);
          setData(res.data.data);
          setTotalPages(res.data.meta.last_page);
          setLoading(false);
          console.log(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          console.error('Search Error:', err);
          setLoading(false);
        });
    }
  };

  const resetSearch = () => {
    // setLoading(true);

    setSearchQuery('');
    setRoleQuery('');
    setStatusQuery('');
    setCurrentPage(1);
    LoadData(1);
  };

  //console.log(this.props)
  return (
    <div>
      <div className='col-md-12 card p-4 mb-4'>
        <div class='row'>
          <div className='col-md-3 mb-lg-0 mb-2 '>
            <input
              className='form-control form-control-sm '
              type='text'
              id='name'
              placeholder='Search by name, email, phone number'
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

          <div class='col-md-3 mb-lg-0 mb-5 mr-4'>
            <select
              onChange={(e) => setRoleQuery(e.target.value)}
              name='role'
              id='role'
              value={roleQuery}
              class='form-control form-control-sm z-index-0'
            >
              <option selected value=''>
                Select Role
              </option>
              <option value='USER'>Users</option>
              <option value='COMPANY'>Company</option>
              <option value='CUSTOMER_CARE'>Customer care</option>
            </select>
          </div>

          <div class='col-md-3 mr-2 mb-lg-0 mb-5 '>
            <select
              onChange={(e) => setStatusQuery(e.target.value)}
              name='status'
              id='status'
              value={statusQuery}
              class='form-control form-control-sm z-index-0'
            >
              {' '}
              <option selected value=''>
                Select Status
              </option>
              <option value='ENABLED'>Enabled</option>
              <option value='DISABLED'>Disabled</option>
            </select>
          </div>

          <div class='col-md-3'>
            <button onClick={handleSearch} className='btn btn-primary mr-2 '>
              Search
            </button>
            <button onClick={resetSearch} className='btn btn-danger '>
              Reset
            </button>
          </div>
          <div class='col'></div>
        </div>
      </div>

      {loading ? (
        <div className='p-5'>
          <Spinner size={70} />
        </div>
      ) : data.length < 1 ? (
        <div className='p-5'>
          <div className='alert alert-warning text-center'>
            No data available yet
          </div>
        </div>
      ) : (
        <div>
          <div className='row mb-4'>
            {data.map((user, i) => (
              <div key={i} className='col-md-3 mb-3'>
                <FadeIn duration='1s' timingFunction='ease-out'>
                  <div className='card card-profile st-member'>
                    {user.status === 'ENABLED' ? (
                      <span
                        data-rh='Archived'
                        className='st-box bg-success'
                      ></span>
                    ) : (
                      <span
                        data-rh='Archived'
                        className='st-box bg-danger'
                      ></span>
                    )}

                    <div className='card-body tx-13'>
                      <center>
                        <div className='avatar avatar-lg'>
                          <span className=''>
                            <img
                              className='avatar-initial rounded-circle'
                              src={user.picture !== null ? user.picture : img}
                            />
                          </span>
                        </div>
                        <h5>{`${user.first_name} ${user.last_name}`}</h5>
                        <small>{user?.email}</small> <br />
                        <small>{user.phone_number}</small>
                        <br />
                        <small>{user.created_at}</small>
                        <hr></hr>
                        <div className='col-md-12'>
                          <button
                            className=' btn btn-primary mb-2'
                            onClick={() => handleViewPoliciesPaidBy(user.id)}
                          >
                            View My Sale(s)
                          </button>
                          <button
                            className=' btn btn-primary'
                            onClick={() => handleViewPoliciesHolder(user.id)}
                          >
                            View My Policie(s)
                          </button>
                        </div>
                        {/* <span
                          className={`p-2 text-white rounded-pill ${
                            user.status === 'ENABLED'
                              ? 'bg-success'
                              : 'bg-danger'
                          }`}
                        >
                          {user.status === 'ENABLED' ? 'Enabled' : 'Disabled'}
                        </span> */}
                      </center>
                    </div>
                  </div>
                </FadeIn>
              </div>
            ))}
          </div>
          <div className='d-flex align-items-center mt-2'>
            <button
              class='btn btn-primary'
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className='mx-2'>
              Page {currentPage} of {totalPages}
            </span>
            <button
              class='btn btn-primary'
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStoreToProps = (store) => {
  return {
    data: store,
  };
};

export default connect(mapStoreToProps)(ListUsers);
